<svg class="svg">
  <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
    <path d="M0.861,0.245 C0.804,0.346,0.748,0.447,0.691,0.548 C0.676,0.575,0.675,0.599,0.691,0.626 C0.738,0.711,0.787,0.795,0.834,0.88 C0.857,0.92,0.879,0.96,0.901,1 L0,1 L0,0 L1,0 C0.954,0.082,0.907,0.163,0.861,0.245"></path>
  </clipPath>
</svg>
<div class="sunray-scroller" id="sunray-scroller">
  <router-outlet></router-outlet>
</div>
<div class="c-preloader">
  <div class="c-preloader-text">
    <div class="sixone-banner-title">
      <div class="sixone-banner-title-inner">
        <div class="sixone-banner-title-row sixone-banner-title-row-1">
          <span class="sixone-banner-title-span-1">Bring</span>
          <span class="sixone-banner-title-span-2">your</span>
        </div>
        <div class="sixone-banner-title-row sixone-banner-title-row-2">
          <span class="sixone-banner-title-span-3">Vision</span>
          <span class="sixone-banner-title-span-4">to</span>
          <span class="sixone-banner-title-span-5">life</span>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- popups -->
<div class="sunray-popup sunray-popup-email" *ngIf="popupType == 'thankpop'">
  <div class="sunray-popup-inner">
    <a href="javascript:void(0)" class="sunray-popup-close" (click)="trueDelPopup()">
      <img src="assets/images/popclose.svg">
    </a>
    <div class="sunray-popup-email-icon">
      <img src="assets/images/mail-pop-icon.svg">
    </div>
    <div class="sunray-popup-email-title">
      We’re thrilled you wanted to learn more about sunray & our work. Please do get in touch if you’d like to be connected directly with the sunray team
    </div>
    <div class="sunray-popup-email-actions">
      <a class="sunray-popup-email-action sunray-popup-email-action-2" href="javascript:void(0)" (click)="trueDelPopup()">Return Home</a>
    </div>
  </div>
</div>
<!-- popups-end -->
