import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, NavigationEnd } from "@angular/router";
import { delay, map } from 'rxjs/operators';
// import { appSetting } from '../app.config';
import { environment } from '../../environments/environment';
import { Observable, of, Subject } from 'rxjs';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { DOCUMENT } from '@angular/common';
declare var $: any;
import * as _ from "lodash";
import { Meta, Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CommonService {
  private _apiUrl;
  private _frontUrl;
  public _siteUrl: any;
  private _apiWordpressUrl;
  router: Router;
  private previousUrl: string;
  private currentUrl: string;
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(@Inject(DOCUMENT) private doc, private _meta: Meta, private _title: Title, router: Router, @Inject(PLATFORM_ID) platformId: Object, private _http: HttpClient,
  ) {
    this.router = router;
    this._apiUrl = environment.apiUrl;
    this._frontUrl = environment.frontUrl;
    this._apiWordpressUrl = environment.getWordPressApiUrl;
    this.platformId = platformId;

    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });

    // this._siteUrl = constants.getApiUrl();

  }

  public platformId;

  // Observable string sources
  private missionAnnouncedSource = new Subject<object>();
  private missionConfirmedSource = new Subject<object>();

  // Observable string streams
  missionAnnounced$ = this.missionAnnouncedSource.asObservable();
  missionConfirmed$ = this.missionConfirmedSource.asObservable();

  // Service message commands
  ChildToParent(mission: object) {
    this.missionAnnouncedSource.next(mission);
  }

  ParentToChild(astronaut: object) {
    this.missionConfirmedSource.next(astronaut);
  }

  getBaseUrl() {
    return this._apiUrl;
  }

  getPreviousUrl() {
    return this.previousUrl;
  }


  isBrowser() {
    try {
      if (isPlatformBrowser(this.platformId)) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }


  showServerError(e) {

  }

  popToast(one, two, three) {

  }

  callApi(url, data, setting, method): Promise<any> {
    //try{


    let headers = new HttpHeaders({ 'content-Type': 'application/json' });

    var apiurl = (setting == 'server') ? this._apiUrl + 'api/' + url : (setting) ? this._apiWordpressUrl + 'acf/v3/options/' + url : this._apiWordpressUrl + 'wp/v2/' + url;

    // console.log('apiurl', apiurl)
    return new Promise((resolve, reject) => {
      // if (method == 'post') {
      //   this._http.post(apiurl, data, { headers })
      //     .subscribe(data => { resolve(data) }, error => { this.showServerError(error) })
      // } else if (method == 'get') {
      //   this._http.get(apiurl, { headers })
      //     .subscribe(data => { resolve(data) }, error => { this.showServerError(error) });
      // }
      resolve(true);
    })
    // }catch(error){

    // }
  }

  setOgMetaTag(og, content, flag) {
    try {


      if (flag) {
        this._meta.updateTag({ "name": og, "content": content });
      } else {
        this._meta.updateTag({ "property": og, "content": content })
      }


    } catch (error) {
      console.log('error***', error)
    }
  }

  createLinkForCanonicalURL(success, type?) {
    var self = this;
    try {
      // console.log("success.yoast_meta", success.yoast_meta)
      _.forEach(success.yoast_meta, function (i) {
        // console.log("i.property", i.property)
        var content = i.content.replace(/&amp;|_/g, '&').replace("We&#039;ve", "we've");
        if (i.name) {
          self._meta.updateTag({ "name": i.name, "content": content });
        } else if (i.property && i.property == 'og:url') {
          // console.log('type', type)
          var slug = (success.slug == 'home') ? "" : success.slug
          var url = (type && type == 'blog-detail') ? `${self._frontUrl}blog-detail/${success.slug}` : `${self._frontUrl}${slug}`;
          // console.log('url', url)
          self._meta.updateTag({ "property": i.property, "content": url });
        } else if (i.property && i.property == 'og:image') {
          var ogImg = (content) ? content : (success.acf && success.acf.image && success.acf.image.url) ? success.acf.image.url : 'https://d1hy2tzaroquh1.cloudfront.net/images/sunray-thumb.jpg';
          self._meta.updateTag({ "property": i.property, "content": ogImg });
        } else if (i.property && i.property == 'og:title') {
          self._title.setTitle(content);
          self._meta.updateTag({ "property": i.property, "content": content });
        } else {
          self._meta.updateTag({ "property": i.property, "content": content });
        }
      })
      // console.log("success.acf", success.acf.image)

      var mainImg = (success.acf && success.acf.image && success.acf.image.url) ? success.acf.image.url : 'https://d1hy2tzaroquh1.cloudfront.net/images/sunray-thumb.jpg';
      this._meta.updateTag({ "property": 'og:image:secure_url', "content": mainImg });
      // if(this.isBrowser()){
      //   $("link[rel='canonical']").remove()
      // }

      // let link: HTMLLinkElement = this.doc.createElement('link');
      // link.setAttribute('rel', 'canonical');
      // this.doc.head.appendChild(link);
      // link.setAttribute('href', success.yoast_meta.yoast_wpseo_canonical);

      // var ogfb = success.yoast_meta.yoast_wpseo_opengraph_image;
      // var ogtw = success.yoast_meta.yoast_wpseo_twitter_image;

      // this._title.setTitle(success.yoast_meta.yoast_wpseo_title);
      // //this._meta.updateTag({ 'name': 'keywords', 'content': success.yoast_meta.yoast_wpseo_metakeywords });
      // this._meta.updateTag({ 'name': 'description', 'content': success.yoast_meta.yoast_wpseo_metadesc });

      // var ogurls = (success.acf && success.acf.og_url)? success.acf.og_url : this._siteUrl;

      // this._meta.updateTag({ "property": 'og:url', "content":  ogurls});
      // this._meta.updateTag({ "property": 'og:type', "content":  'website'});
      // this._meta.updateTag({ "property": 'og:title', "content":  success.yoast_meta.yoast_wpseo_opengraph_title});
      // this._meta.updateTag({ "property": 'og:description', "content":  success.yoast_meta.yoast_wpseo_opengraph_description});

      // if(ogfb && ogtw){
      //   console.log('1111111')
      //   this._meta.updateTag({ "property": 'og:image', "content":  ogfb});
      //   this._meta.updateTag({ "name": 'twitter:image', "content":  ogtw});
      // }else if(ogfb){
      //   console.log('22222')
      //   this._meta.updateTag({ "property": 'og:image', "content":  ogfb});
      //   this._meta.updateTag({ "name": 'twitter:image', "content":  ogfb});
      // }else if(ogtw){
      //   console.log('33333333')
      //   this._meta.updateTag({ "property": 'og:image', "content":  ogtw});
      //   this._meta.updateTag({ "name": 'twitter:image', "content": ogtw});
      // }else{
      //   if(success.featured_media > 0){
      //     // this.getFeaturedgtIm(success.featured_media)
      //   }
      // }

      // this._meta.updateTag({ "name": 'og:image:width', "content":  '1200'});
      // this._meta.updateTag({ "name": 'og:image:height', "content":  '633'});
      // //this._meta.updateTag({ "name": 'twitter:card', "content":  success.yoast_meta.yoast_wpseo_title});
      // this._meta.updateTag({ "name": 'twitter:title', "content":  success.yoast_meta.yoast_wpseo_twitter_title});
      // this._meta.updateTag({ "name": 'twitter:description', "content":  success.yoast_meta.yoast_wpseo_twitter_description});



      // this.addGoogleTagManager(success.acf || {});
    } catch (error) {
      console.log("error", error)
    }

  }

  callServerApi(url, data, method): Promise<any> {
    // try {
    let headers;
    headers = new HttpHeaders({ 'content-Type': 'application/json', });

    // console.log(this._apiUrl + 'api/' + url, data)

    ////console.log(DetectRTC.osName)

    // if(DetectRTC.osName === "Mac OS X"){
    //   this._apiUrl = "https://api.jabri.co/";
    // }
    //console.log(url)
    return new Promise((resolve, reject) => {
      // if (method == 'post') {
      //   this._http.post(this._apiUrl + 'api/' + url, data, { headers })
      //     .subscribe(data => {
      //       // console.log("data", data)
      //       resolve(data);
      //     }, error => {
      //       console.log("error", error)
      //       this.showServerError(error)

      //     })
      // } else if (method == 'get') {
      //   // let params: { appid: 'id1234', cnt: '5' }
      //   this._http.get(this._apiUrl + 'api/' + url, { headers })
      //     .subscribe(data => {
      //       resolve(data)
      //     }, error => { this.showServerError(error) })
      // }

      resolve(true);
    })
    // } catch (error) {
    //   console.log("error11", error)
    // }
  }

  // getFeaturedgtIm(id){
  //   var self = this;
  //   try{

  //     this.callApi('media/'+id, null, false, 'get').then(success =>{

  //       this._meta.updateTag({ "property": 'og:image', "content":  success.source_url});
  //       this._meta.updateTag({ "name": 'twitter:image', "content":  success.source_url});

  //     });
  //   }catch(error){
  //     console.log("error", error)
  //   }
  // }


}
