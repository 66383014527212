import { Injectable } from '@angular/core';
declare var $: any;
import { CommonService } from './common/common.service';

interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  { name: 'modernizr-custom', src: '../assets/js/modernizr-custom.js' },
  { name: 'popper', src: '../assets/js/popper.js' },
  { name: 'bootstrap', src: '../assets/js/bootstrap.js' },
  { name: 'loco', src: '../assets/js/loco.js' },
  { name: 'scrollmagic', src: '../assets/js/scrollmagic.js' },
  { name: 'bxslider', src: '../assets/js/bxslider.js' },
  { name: 'animate', src: '../assets/js/animate.js' },
  { name: 'mason', src: '../assets/js/mason.js' },
];

declare var document: any;

@Injectable()
export class DynamicScriptLoaderService {

  private scripts: any = {};

  constructor(public Commonservices: CommonService,) {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    return new Promise((resolve, reject) => {
      const promises: any[] = [];
      console.log('2222222222', scripts)
      scripts.forEach((script) => promises.push(this.loadScript(script)));
      console.log('444444444')
      var pro = Promise.all(promises);
      resolve(true)
    });

  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      try {
        if (this.Commonservices.isBrowser()) {
          console.log('3333333')
          if (!this.scripts[name].loaded) {
            //load script
            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = this.scripts[name].src;
            //console.log("script", script)
            if (script.readyState) {  //IE
              script.onreadystatechange = () => {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                  script.onreadystatechange = null;
                  this.scripts[name].loaded = true;
                  resolve({ script: name, loaded: true, status: 'Loaded' });
                }
              };
            } else {  //Others
              script.onload = () => {
                this.scripts[name].loaded = true;
                resolve({ script: name, loaded: true, status: 'Loaded' });
              };
            }
            script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
            document.getElementsByTagName('head')[0].appendChild(script);
          } else {
            resolve({ script: name, loaded: true, status: 'Already Loaded' });
          }
        }
      } catch (error) {

      }

    });
  }

  removeScript(src) {
    var self = this;
    try {
      if (this.Commonservices.isBrowser()) {
        $("script[src='../assets/js/" + src + ".js']").remove();
        //console.log("self.scripts", self.scripts)
        self.scripts[src].loaded = false;
        //{script: name, loaded: true, status: 'Loaded'}
      }
    } catch (error) {
      console.log("error", error)
    }
  }

}